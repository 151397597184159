import React from 'react';
import { Mint } from './Mint';
const App = () => {


  return (
    <div>
      <Mint />
    </div>
  );
};

export default App;
