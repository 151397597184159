import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./css/index.css";

import "@rainbow-me/rainbowkit/styles.css";
// import {
//   getDefaultWallets,
//   RainbowKitProvider, lightTheme,
// } from "@rainbow-me/rainbowkit";
// import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
// import { alchemyProvider } from "wagmi/providers/alchemy";
// import { publicProvider } from "wagmi/providers/public";
import App from "./App";

// const { chains, provider, webSocketProvider } = configureChains(
//   [
//     chain.mainnet,
//     // chain.rinkeby,
//     // chain.polygon,
//     // chain.optimism,
//     // chain.arbitrum,
//     ...(process.env.REACT_APP_ENABLE_TESTNETS === "true"
//       ? [chain.goerli, chain.kovan, chain.rinkeby, chain.ropsten]
//       : []),
//   ],
//   [
//     alchemyProvider({ alchemyId: "_gg7wSSi0KMBsdKnGVfHDueq6xMB9EkC" }),
//     publicProvider(),
//   ]
// );

// const { connectors } = getDefaultWallets({
//   appName: "Murcat",
//   chains,
// });

// const wagmiClient = createClient({
//   autoConnect: true,
//   connectors,
//   provider,
//   webSocketProvider,
// });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  //   <WagmiConfig client={wagmiClient}>
  //     <RainbowKitProvider coolMode chains={chains} theme={lightTheme({
  //       accentColor: '#ffffff',
  //       accentColorForeground: '#000000',
  //       fontStack: 'system',
  //       borderRadius: 'small',
  //     })}>
  <App />
  //     </RainbowKitProvider>
  //   </WagmiConfig>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
