import React, { useState } from 'react';
import "./css/App.css";
// import { ConnectButton } from "@rainbow-me/rainbowkit";

// import {
//   useAccount,
//   useConnect,
//   useContractRead,
//   useContractWrite,
//   useWaitForTransaction,
// } from 'wagmi';
// import contractInterface from './abi/contract-abi.json';

export function Mint({ ready }: any) {
  // const contractConfig = {
  //   addressOrName: '0xb4b561bB19f3163bD606e52087ad94130Dd78B5d',
  //   contractInterface: contractInterface,
  // };
  // const [totalMinted, setTotalMinted] = React.useState(0);
  // const [count, setCount] = useState(1);
  // const { data } = useAccount()
  // const [buyCount, setBuyCount] = React.useState(0);

  // const { data: totalSupplyData } = useContractRead(
  //   contractConfig,
  //   'totalSupply',
  //   { watch: true }
  // );

  // const { data: buyCountData } = useContractRead(
  //   contractConfig,
  //   'owners',
  //   { watch: true, args: data?.address }
  // );

  // React.useEffect(() => {
  //   if (totalSupplyData) {
  //     setTotalMinted(totalSupplyData.toNumber());
  //   }
  // }, [totalSupplyData]);

  // React.useEffect(() => {
  //   if (buyCountData) {
  //     setBuyCount(buyCountData.toNumber());
  //   }
  // }, [totalSupplyData]);

  // const mintNft = () => {
  //   console.log('mint');
  //   // mint()
  // };

  // const {
  //   data: mintData,
  //   write: mint,
  //   isLoading: isMintLoading,
  // } = useContractWrite(contractConfig, 'mint', {
  //   overrides: { value: "0" },
  //   onError(error) {
  //     console.log('Error', error)
  //     let m = "" + error
  //     if (m.indexOf('Over 5') !== -1) {
  //       m = 'Mint up to 5'
  //     }
  //     if (m.indexOf('Exceed total count') !== -1) {
  //       m = 'Exceed total count'
  //     }
  //     alert(m)
  //   },
  // });

  // const { isSuccess: txSuccess } = useWaitForTransaction({
  //   hash: mintData?.hash,
  // });

  // const isMinted = txSuccess;

  // const { isConnected } = useConnect();

  return (
    <div className='main'>
      <div className='bac'>
        <div className="container">
          <div className='logoImage'>
          </div>
          <div className='allTip'>
            <div className='tip'>
              <span className='tip1'>What is this? Guess what?</span>
              <span className='tip2'>I can’t tell you what it wanted to do or what it thought of for the time being. I just know that he suddenly understood.</span>
              <div>
                <a href='https://twitter.com/intent/follow?screen_name=MurcatCoin' target='_blank' className='tip3 footer-mint css-button css-btn' rel="noreferrer">Follow on X</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='footer flex'>
        <div className='footer-count'>{totalMinted}/799</div>
        <div><ConnectButton label="Connect" showBalance={false} accountStatus={{
          smallScreen: 'avatar',
          largeScreen: 'full',
        }} /></div>
        {isConnected && (
          <div>
            <button className='footer-mint css-button css-btn' onClick={() => mintNft()}>MINT</button>
          </div>
        )}
      </div> */}
    </div >
  )
}